import { LocalStorageKeys, PRODUCTION } from '$constants';
import { HELLO_RETAIL_UUID, getCookie, setCookie } from '$lib/helpers';
import { http } from '$lib/http';
import { ICookieConsentWindow } from '$models/raptor-tracking';

const baseQuery = `https://core.helloretail.com/serve`;

const clickTrackingQuery = `${baseQuery}/collect/click`;
const getTrackingUserQuery = `${baseQuery}/trackingUser`;

const defaultTrackingId = '000000000000000000000000';

type TrackingUserResponse = {
    success: boolean;
    id: string;
};
type TrackingClickResponse = {
    success: boolean;
    message: string;
};

function checkConsent() {
    try {
        if ((window as ICookieConsentWindow)?.CookieInformation?.getConsentGivenFor('cookie_cat_marketing')) {
            return true;
        } else {
            // Track userdata on development for testing
            return PRODUCTION ? false : true;
        }
    } catch (error) {
        return PRODUCTION ? false : true;
    }
}

export const getCurrentTrackingUser = async () => {
    const consent = await checkConsent();
    if (!consent) {
        return defaultTrackingId;
    }
    const cookieUser = await getCookie(LocalStorageKeys.HelloRetailTrackingUser);
    if (cookieUser) {
        return cookieUser;
    }
    const trackingUser = await getTrackingUser();
    if (trackingUser?.success) {
        await setCookie(LocalStorageKeys.HelloRetailTrackingUser, trackingUser.id, 60 * 24 * 365);
        return trackingUser.id;
    }
    return defaultTrackingId;
};

const getTrackingUser = async (): Promise<TrackingUserResponse | undefined> => {
    const uuid = HELLO_RETAIL_UUID;
    if (!uuid) {
        return undefined;
    }

    const response = await http(getTrackingUserQuery, {
        method: 'POST',
        body: JSON.stringify({ websiteUuid: uuid }),
    });

    const json = await response.json();

    return json;
};

export const trackHelloRetailClick = async (source: string) => {
    try {
        if (!source) {
            return;
        }
        const trackingUser = await getCurrentTrackingUser();
        if (!trackingUser) {
            return;
        }
        const uuid = HELLO_RETAIL_UUID;
        if (!uuid) {
            return;
        }
        const response = await http(clickTrackingQuery, {
            method: 'POST',
            body: JSON.stringify({ websiteUuid: uuid, trackingUserId: trackingUser, source }),
        });
        const json: TrackingClickResponse = await response.json();

        return json?.success;
    } catch (error) {
        return;
    }
};
