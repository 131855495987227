import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { B2CDeliveryMethod } from '$constants';
import { Ribbon } from './components/ribbon';
import { Splash } from './components/splash';
import { Image } from '../image';
import { mq } from '$lib/helpers';
import { useProductRibbons } from '~/hooks/use-product-ribbons';

type Props = {
    imageUrl: string | undefined;
    itemGiftShop: boolean;
    isOffer?: boolean;
    splashes: Array<string> | undefined;
    b2CDeliveryMethod: B2CDeliveryMethod | undefined;
    onClick?: () => void;
    className?: string;
    productName?: string;
    splashAltText?: string;
    eagerLoad?: boolean;
    resourceID?: string;
};

export const ProductImage: FC<Props> = (props: Props) => {
    const {
        imageUrl,
        onClick,
        itemGiftShop,
        b2CDeliveryMethod,
        splashes,
        className,
        isOffer = false,
        productName,
        splashAltText,
        eagerLoad,
        resourceID,
    } = props;
    const { ribbons } = useProductRibbons(itemGiftShop, b2CDeliveryMethod, isOffer);

    return (
        <ImageContainer className={className} column alignItems="center">
            {!!imageUrl && (
                <StyledImage
                    priority={eagerLoad}
                    onClick={onClick}
                    src={imageUrl}
                    alt={productName}
                    height={213}
                    width={160}
                    resourceID={resourceID}
                />
            )}
            {!!ribbons?.length && (
                <RibbonContainer column alignItems="flex-start">
                    {ribbons?.map((x, i) => (
                        <Ribbon key={`${x.text}-${i}`} type={x.color}>
                            {x.text}
                        </Ribbon>
                    ))}
                </RibbonContainer>
            )}
            {!!splashes?.length && (
                <SplashContainer>
                    {splashes?.map((x) => (
                        <Splash priority={eagerLoad} key={x} src={x} height={90} width={90} alt={splashAltText} />
                    ))}
                </SplashContainer>
            )}
        </ImageContainer>
    );
};

const StyledImage = styled(Image)(({ theme }) => ({
    marginTop: theme.space[3],
    marginBottom: theme.space[3],
    [mq(0, 'sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
    },
}));

const RibbonContainer = styled(Flex)(() => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    [mq(0, 'sm')]: {
        maxWidth: '100%',
    },
}));

const SplashContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: theme.space[1],
    width: '45%',
    height: 'auto',
    [mq('md')]: {
        right: theme.space[5],
        width: '35%',
    },
}));

const ImageContainer = styled(Flex)(({ theme }) => ({
    position: 'relative',
    cursor: 'pointer',
    marginBottom: theme.space[5],
    justifyContent: 'center',
    [mq(0, 'sm')]: {
        maxHeight: '250px',
    },
    img: {
        width: 'auto',
    },
}));
