import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ifProp } from 'styled-tools';
import { Flex } from '$components/layouts';
import { SvgIcon } from './../svg-icon';

export enum ReviewIconType {
    // eslint-disable-next-line no-unused-vars
    hearts = 'hearts',
    stars = 'stars',
}

type IconReviewProps = {
    size?: number;
    score: number;
    iconType?: ReviewIconType;
    numberOfIcons?: number;
    onScoreChanged?: (score: number) => void;
    color?: string;
};

// eslint-disable-next-line react/display-name
export const IconReview: FC<IconReviewProps> = memo(
    ({
        size = 24,
        score,
        iconType = ReviewIconType.stars,
        numberOfIcons = 5,
        onScoreChanged,
        color,
        ...props
    }: IconReviewProps) => (
        <Flex {...props}>
            {Array.from({ length: numberOfIcons }, (_, i) => i + 1).map((iconNumber) => {
                const individualScore = iconNumber > score + 1 || score === 0 ? 0 : score >= iconNumber ? 1 : score % 1;
                return (
                    <IndividualIcon
                        key={`icon-${iconNumber}`}
                        size={size}
                        individualScore={individualScore}
                        iconType={iconType}
                        onClick={onScoreChanged ? () => onScoreChanged(iconNumber) : undefined}
                        color={color}
                    />
                );
            })}
        </Flex>
    )
);

type IconProps = {
    size?: number;
    individualScore: number;
    iconType: ReviewIconType;
    onClick?: () => void;
    color?: string;
};

// eslint-disable-next-line react/display-name
const IndividualIcon: FC<IconProps> = memo(({ individualScore, iconType, size = 24, onClick, color }: IconProps) => {
    const theme = useTheme();

    return (
        <div style={{ height: size, width: size }} onClick={onClick}>
            <div
                style={{
                    width: size,
                    height: size,
                    position: 'relative',
                    marginRight: '2px',
                }}
            >
                {individualScore < 1 && (
                    <StyledIcon
                        color={theme.colors.primaryGreyLight}
                        clickable={!!onClick}
                        zIndex={0}
                        left={0}
                        size={size}
                        svg={iconType === ReviewIconType.hearts ? 'heartSolid' : 'starFull'}
                        disableStyledContainer
                    />
                )}
                {individualScore > 0.25 && individualScore < 0.75 && (
                    <StyledIcon
                        color={color ? color : theme.colors.primaryTheme}
                        clickable={!!onClick}
                        zIndex={0}
                        left={-(size * 0.01)}
                        size={size}
                        svg={iconType === ReviewIconType.hearts ? 'heartSolid' : 'starHalf'}
                        disableStyledContainer
                    />
                )}
                {individualScore >= 0.75 && (
                    <StyledIcon
                        color={color ? color : theme.colors.primaryTheme}
                        clickable={!!onClick}
                        zIndex={1}
                        left={0}
                        size={size}
                        svg={iconType === ReviewIconType.hearts ? 'heartSolid' : 'starFull'}
                        disableStyledContainer
                    />
                )}
            </div>
        </div>
    );
});

const StyledIcon = styled(SvgIcon)<{ color: string; zIndex: number; left: number; clickable: boolean }>(
    ({ color, zIndex, left }) => ({
        color: color,
        position: 'absolute',
        zIndex: zIndex,
        left: left,
    }),
    ifProp(
        { clickable: true },
        {
            cursor: 'pointer',
        }
    )
);
