import styled from '@emotion/styled';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';
import { mq } from '$lib/helpers';

export type RibbonProps = {
    type: 'primary' | 'warning' | 'info';
    top?: boolean;
};

export const Ribbon = styled.span<RibbonProps>(
    ({ theme }) => ({
        fontSize: theme.fontSizes.xxxs,
        fontWeight: theme.fontWeights.medium,
        textTransform: 'uppercase',
        color: theme.colors.white,
        display: 'inline-block',
        padding: theme.space[2],

        [mq(0, 'sm')]: {
            padding: theme.space[1],
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            fontSize: theme.fontSizes.xxxxs,
        },
    }),
    switchProp('type', {
        primary: ({ theme }) => ({
            backgroundColor: theme.colors.gift,
        }),
        warning: ({ theme }) => ({
            backgroundColor: theme.colors.red,
        }),
        info: ({ theme }) => ({
            backgroundColor: theme.colors.black,
        }),
    }),
    ifProp('top', ({ theme }) => ({
        borderBottomRightRadius: theme.general.borderRadius,
        ':first-child': {
            borderTopLeftRadius: theme.general.borderRadius,
        },
    })),
    ifNotProp('top', ({ theme }) => ({
        borderTopRightRadius: theme.general.borderRadius,
        ':last-child': {
            borderBottomLeftRadius: theme.general.borderRadius,
        },
    }))
);
