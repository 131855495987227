import React, { FC } from 'react';
import { switchProp } from 'styled-tools';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Flex } from '$components/layouts';
import { ReviewBar } from '../../review-bar/review-bar';
import { Text } from '../../text';
import { IBogOgIdeBusinessProductISanitizedPriceModel } from '$models';
import { ellipsis } from '$lib/style-helpers';

export type Props = {
    image?: React.ReactNode;
    name?: string;
    format?: string;
    author?: string;
    prices?: IBogOgIdeBusinessProductISanitizedPriceModel;
    review?: number;
    reviewTotal?: number;
    hasSpecialPrice?: boolean;
    onProductClick?: () => void;
};

export const ProductCardLayout: FC<Props> = ({
    name,
    format,
    image,
    author,
    prices,
    review,
    reviewTotal,
    hasSpecialPrice,
    onProductClick,
}: Props) => {
    const theme = useTheme();

    const formerPrice =
        prices?.actualPrice && (prices?.actualPriceAmount || 0) < (prices?.retailPriceAmount || 0)
            ? prices?.retailPrice
            : undefined;
    const salesPrice = prices?.actualPrice || prices?.retailPrice;

    return (
        <Content column>
            {!!image && image}
            <Header onClick={onProductClick}>{name}</Header>
            <Author size={theme.fontSizes.xs} color={theme.colors.grey}>
                {author}
            </Author>

            <PriceContainer alignItems="center" gap="extrasmall">
                {hasSpecialPrice ? (
                    <SalesPriceText type="salesPrice">{prices?.actualPrice}</SalesPriceText>
                ) : (
                    <>
                        {!!salesPrice && (
                            <SalesPriceText type={formerPrice ? 'salesPrice' : 'currentPrice'}>
                                {salesPrice}
                            </SalesPriceText>
                        )}
                        {!!formerPrice && <SalesPriceText type="formerPrice">{formerPrice}</SalesPriceText>}
                    </>
                )}
            </PriceContainer>

            {format && (
                <Format font="regular" fontWeight="Bold">
                    {format}
                </Format>
            )}

            {!!review && <ReviewBar score={review || 0} total={reviewTotal} />}
        </Content>
    );
};

const Content = styled(Flex)({
    height: '100%',
});

const Header = styled(Text)(({ theme }) => ({
    margin: 0,
    marginBottom: theme.space[1],
    ...theme.mixins.useTextStyle('smallHeader'),
    fontWeight: theme.fontWeights.bold,
    ...ellipsis,
}));

const Author = styled(Text)(({ theme }) => ({
    marginBottom: theme.space[2],
    ...theme.mixins.useTextStyle('bodySmall'),
    fontStyle: 'italic',
    ...ellipsis,
}));

const Format = styled(Text)(({ theme }) => ({
    display: 'flex',
    alignSelf: 'flex-start',
    backgroundColor: theme.colors.primaryGreyLight,
    padding: `${theme.space[1]} ${theme.space[2]}`,
    borderRadius: '10px',
    textTransform: 'uppercase',
    fontSize: '10px',
    marginBottom: theme.space[3],
}));

type SalesPriceProps = {
    type?: 'salesPrice' | 'formerPrice' | 'currentPrice';
};

const PriceContainer = styled(Flex)(({ theme }) => ({
    marginBottom: theme.space[1],
}));

const SalesPriceText = styled(Text)<SalesPriceProps>(
    ({ theme }) => ({
        // marginBottom: theme.space[1],
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.bold,
    }),
    switchProp('type', {
        salesPrice: ({ theme }) => ({
            color: theme.colors.red,
        }),
        formerPrice: ({ theme }) => ({
            color: theme.colors.primaryGrey,
            fontWeight: theme.fontWeights.medium,
            textDecorationLine: 'line-through',
        }),
        currentPrice: ({ theme }) => ({
            color: theme.colors.black,
        }),
    })
);
