import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { IconReview, ReviewIconType } from '../icon-review';

type Props = {
    score: number;
    total?: number;
};

export const ReviewBar: FC<Props> = ({ score, total }: Props) => {
    if (score > 100) {
        return null;
    }

    return (
        <FixedReviewContainer>
            {score <= 100 && (
                <Flex alignItems="center">
                    <IconReview score={(score / 100) * 5} iconType={ReviewIconType.stars} size={13} />
                    <ReviewTotal>({total})</ReviewTotal>
                </Flex>
            )}
        </FixedReviewContainer>
    );
};

const ReviewTotal = styled.span(({ theme }) => ({
    paddingLeft: theme.space[1],
    paddingTop: '2px',
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.fontSizes.xs,
    color: theme.colors.primaryGrey,
}));

const FixedReviewContainer = styled(Flex)({
    height: '20px',
});
